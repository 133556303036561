



















































import { Dictionary } from "@/types/helpers";
import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import { RawLocation } from "vue-router";

@Component
export default class VAutocompleteFilter extends Vue {
  @Prop({ default: null }) label!: string | null;
  @Prop() manager!: RawLocation;
  @Prop({ default: "" }) manageName!: string;
  @Prop() items!: Dictionary<string | number | unknown>[];
  @Prop({ default: true }) checkDuplicate!: boolean;
  @Prop({ default: true }) createNoData!: boolean;
  searchText: string | null = null;

  get canCreate(): boolean {
    return (
      this.createNoData &&
      ((this.items &&
        !this.items.some((item) => item.text == this.searchText)) ||
        !this.checkDuplicate)
    );
  }

  get labelLower(): string {
    return this.label ? this.label.toLowerCase() : "";
  }

  @Emit()
  create(): string {
    return this.searchText ?? "";
  }
}
