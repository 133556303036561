

































import { Dictionary } from "@/types/helpers";
import { Subject } from "@/types/subject";
import { Component, Vue, VModel, Ref } from "vue-property-decorator";
import subjectModule from "@/store/modules/subjects";
import Factory from "@/types/Factory";
import VAutocompleteFilter from "@/components/utility/VAutocompleteFilter.vue";

@Component({
  components: {
    VAutocompleteFilter,
  },
})
export default class SelectSubject extends Vue {
  @VModel({ type: String }) subject!: string;

  get subjects(): Dictionary<string | number>[] {
    const subjects: Subject[] = subjectModule.subjects;
    if (!subjects) return [];
    return subjects.map((item: Subject) => ({
      value: item.id.toString(),
      text: item.name,
      color: item.color,
    }));
  }
}
