



















import { Dictionary, TaskType } from "@/types/helpers";
import { Component, Vue } from "vue-property-decorator";

@Component
export default class SelectType extends Vue {
  get types(): Dictionary<string | number>[] {
    return Object.values(TaskType)
      .filter((key) => Number.isInteger(key))
      .map((key) => ({
        value: key.toString(),
        text: this.$tc(`task.types.${key}.label`, 1).toString(),
        icon: this.$t(`task.types.${key}.icon`).toString(),
      }));
  }
}
